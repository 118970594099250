<template>
  <div class="container md-10 mb-5">
    <header class="mt-5">
      <ul class="d-flex align-content-lg-between align-items-center mb-2" id="headerDesk">
        <li class="mr-1">Home</li>
        <li class="text-white">/ Banners</li>
      </ul>
      <div class="welcomeMobile">
        <router-link to="/" class="imgWelCont">
          <img src="../assets/image/iconesMobile/grayArrow.svg" class="grayArrow">
        </router-link>
        <div class="txtWelCont">
          <p>Banners</p>
        </div>
    </div>
    </header>
    <!-- new scraps -->
    <scrap />
  </div>
</template>

<script>
import HTTP from "@/api_system";
import scrap from "@/components/recados/master/_holderScrap_Master.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { scrap },
  data() {
    return {};
  },
  mounted() {
    this.GetListNotices();
  },
  methods: {
    ...mapActions(["GetListNotices"]),
    modal() {
      $("#oponCreate").modal("show");
    },

    excluirList(id) {
      HTTP.delete(`services/app/Notice/Delete?Id=${id}`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(() => {
          // alert('deletou')
          this.messageSucess("Recado deletado com sucesso.");
        })
        .catch(err => this.messageError(err));
    },

    imageCurrent(url, link) {
      this.shows = true;
      this.urlImage = url;
      this.link = link;
    },

    edite(id, dias, data) {
      (this.update = true), (this.date = dias);
      this.data = data;
      this.id = id;
    },

    updatesDays(date) {
      let format = this.data.slice(0, 10);
      let datas = this.somaDate(format, Number(date - 1));

      // console.log(this.somaDate(format, Number(date - 2)));

      HTTP.post(
        `services/app/Notice/Edit?NoticeId=${this.id}&dateFinale=${datas}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(() => {
          $("#ModalCenterEdite").modal("hide");
          this.messageSucess("Data Alterada com Sucesso.");
        })
        .catch(err => this.messageError(err));
    },

    somaDate(date, days) {
      let date1 = date.split("/");
      let day = date1[2] + "-" + date1[1] + "-" + date1[0];
      let dateCurrent = new Date(day);

      dateCurrent.setDate(dateCurrent.getDate());
      let myDate = new Date(day);

      myDate.setDate(myDate.getDate() + (days + 1));

      let ano = myDate.getFullYear();
      let dia = myDate.getDate();

      if (dia < 10) {
        dia = "0" + dia;
      }

      let mes = myDate.getMonth() + 1;

      if (mes < 10) {
        mes = "0" + mes;
      }

      return `${ano}-${mes}-${dia}`;
    }
  },
  computed: {
    ...mapGetters(["get_message", "getNotices"])
  },
  watch: {
    get_message: function() {
      if (this.get_message == 6) {
        this.GetListNotices();
      }
    },
    "getNotices.error": function() {
      if (this.getNotices.error) {
        this.toast(
          "Ops, erro ao carregar os banners da base de dados.",
          this.$toast.error
        );
      }
    }
  }
};
</script>

<style scoped>
* {
  list-style: none;
  padding: 0;
}

li {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #98c4e6;
}

header {
  border-bottom: 2px solid #3f88c1;
}
@media screen and (min-width:768px){
  .welcomeMobile{
    display: none!important;
  }
}
@media screen and (max-width:767px){
  #headerDesk{
    display: none!important;
  }
  header{
    margin-top: 0!important;
    border: none;
  }
  .welcomeMobile{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #217ABF;
    height: 40px;
    margin-bottom: 3%;
  }
  .imgWelCont{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont{
    width: 77%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .txtWelCont p{
    margin-bottom: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .imgWelCont img{
    padding-left: 7%;
    width: 26%;
  }
}
</style>
