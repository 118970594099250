<template>
  <ul :class="`nav ${navClass}`" id="myTab" role="tablist">
    <li class="nav-item" v-for="(item, index) in tabs" :key="index">
      <a
        class="nav-link"
        :class="index == 0 ? 'active' : ''"
        :id="`${item.tabName}-tab`"
        data-toggle="tab"
        :href="`#${item.tabName}`"
        role="tab"
        :aria-controls="item.tabName"
        aria-selected="true"
        @click="$emit('tabsMovie', index, item)"
        >{{ item.description }}</a
      >
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: []
    },
    navClass: {
      type: String,
      default: "nav-tabs"
    }
  }
};
</script>

<style scoped>
.nav-tabs-blue {
  border-bottom: 2px solid #98c4e6;
}

nav-tabs-blue .nav-item.show .nav-link,
.nav-tabs-blue .nav-link.active {
  color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 5px solid #fff;
  border-radius: 2px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-bottom: 3px;
}

.nav-tabs-blue .nav-link {
  border: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: #acdbff;
}

.nav-tabs {
  border: 0px;
  padding-left: 15px;
}

nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #3f88c1;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 6px solid #3f88c1;
  border-radius: 2px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  padding-bottom: 3px;
}

.nav-tabs .nav-link {
  border: 0px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  color: #acdbff;
}
</style>
