<template>
  <div class="mt-4 background-div-banner">
    <div
      class="thumbnail"
      v-for="(item, index) in listCurrent"
      :key="`${index}_${item.id}`"
      :id="`thumbnail_${item.id}`"
      :class="index > 0 ? 'ml-2' : ''"
    >
      <div
        class="thumbnail-banner"
        @mouseenter="callShadow(item.id)"
        @mouseleave="outShadow(item.id)"
        :id="`thumbnail-banner_${item.id}`"
      >
        <img :src="getImg(item.image)" alt="banner" />
        <div
          style="display:none;"
          :id="`thumbnail-shadow_${item.id}`"
          class="thumbnail-shadow"
        ></div>
        <button
          :id="`btn-banner_${item.id}`"
          class="btn btn-banner disable"
          @click="openModal('modalShow', { name: item.title, img: item.image, type: item.type })"
        >
          <font-awesome-icon icon="fas fa-eye mr-1" />
        </button>
        <button
          :id="`btn-edit_${item.id}`"
          class="btn btn-edit disable"
          @click="openModal('modalCreateOrEdit', { notice: item })"
        >
          <font-awesome-icon icon="fas fa-pen mr-1" />
        </button>
        <button
          :id="`btn-trash_${item.id}`"
          class="btn btn-trash disable"
          @click.prevent="
            openModal('modalRemove', { id: item.id, title: item.title })
          "
        >
          <font-awesome-icon icon="fas fa-trash-alt mr-1" />
        </button>
      </div>
      <div class="mt-3">
        <p class="label_default info-thumbnail-banner">
          Data de Início: {{ getDateBR(item.dateInitial) }}
        </p>
        <p class="label_default mt-3 info-thumbnail-banner">
          {{ getBetweenDate(item.dateInitial, item.dateFinale) }}
        </p>
      </div>
    </div>
    <component
      :is="cp"
      :param="param"
      :disabled.sync="disabled"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
export default {
  components: {
    modalRemove: () => import("../modal/remove/modalRemove.vue"),
    modalShow: () => import("../modal/show/modalShow.vue"),
    modalCreateOrEdit: () => import("../modal/create/modalCreateOrEdit.vue")
  },
  props: {
    listCurrent: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      disabled: false,
      param: "",
      cp: ""
    };
  },
  methods: {
    callShadow(param) {
      $(`#btn-banner_${param}`).removeClass("disable");
      $(`#btn-edit_${param}`).removeClass("disable");
      $(`#btn-trash_${param}`).removeClass("disable");
    },
    outShadow(param) {
      $(`#btn-banner_${param}`).addClass("disable");
      $(`#btn-edit_${param}`).addClass("disable");
      $(`#btn-trash_${param}`).addClass("disable");
    },
    openModal(modalName, param) {
      this.param = param;
      this.cp = modalName;
    },
    closeModal(modalName) {
      $(`#${modalName}`).modal("hide");
      this.cp = "";
    },
    getBetweenDate(dateI, dateF) {
      const diffTime = new Date(dateF) - new Date(dateI);
      const diffDay = diffTime / (1000 * 60 * 60 * 24);
      if (diffDay > 1) {
        return `Ativo por: ${diffDay} dias`;
      } else if (diffDay > 0) {
        return `Ativo por: ${diffDay} dia`;
      } else {
        return `Ativo por: Encerra no mesmo dia do início`;
      }
    }
  },
  watch: {
    cp() {
      if (this.cp != "") {
        setTimeout(() => {
          $(`#${this.cp}`).modal("show");
        }, 200);
      }
    }
  }
};
</script>

<style scoped>
.info-thumbnail-banner {
  size: 13px;
  color: #98c4e6;
}

.thumbnail {
  width: 312px;
  height: 250px;
}

.thumbnail-banner {
  position: relative;
  width: 100%;
  height: 130px;
}

.thumbnail-banner img {
  width: 312px;
  height: 130px;
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  display: flex;
}

.background-div-banner {
  display: flex;
  overflow-x: auto;
  width: 100%;
}

.thumbnail-shadow {
  box-shadow: inset 0 0 0 4000px rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  border-radius: 8px;
}

.disable {
  display: none;
}

.btn {
  padding: 2px 2px 3px 3px !important;
  border-radius: 6px;
}

.btn-banner {
  max-height: 27px;
  color: #ffffff !important;
  background-color: #f38235;
  border-radius: 6px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: 76px;
  right: 0;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.btn-banner:hover,
.btn-banner:active,
.btn-banner.focus,
.btn-banner:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  background-color: #e0a638 !important;
}

.btn-edit {
  max-height: 27px;
  color: #ffffff !important;
  background-color: #3f88c1;
  border-radius: 6px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: 40px;
  right: 0;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.btn-edit:hover,
.btn-edit:active,
.btn-edit.focus,
.btn-edit:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  background-color: #3f89c1b2 !important;
}

.btn-trash {
  color: #ffffff !important;
  background-color: #dc3545;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  height: 30px;
  width: 30px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.btn-trash:hover,
.btn-trash:active,
.btn-trash.focus,
.btn-trash:focus-visible {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  background-color: #dc3546b7 !important;
  border-radius: 6px;
}
</style>
